<template>
    <div class="allocatepage-index">
        <CompContent ref="content" :title="title" :error="error">
            <div class="module-box">
                <div class="item-base" v-for="(item, index) in modules" :key="idx" :style="{ width: X(item.width), height: Y(item.height), marginTop: Y(item.marginTop), marginLeft: X(item.marginLeft), marginRight: X(item.marginRight), marginBottom: Y(item.marginBottom) }">
                    <div class="item-box">
                        <Menu
                            v-if="item.type === 'Menu'"
                            :menuList="checkJson(item.data)"
                            :menuCode="menuCode"
                            :dataFrom="item.dataFrom"
                            :menuType="item.menuType"
                            :rows="item.rows"
                            :mode="item.mode"
                            :hasBorder="item.hasBorder"
                            :shadow="item.shadow"
                            :columnNum="item.columnNum"
                            :wp="wp"
                            :hp="hp"
                            @click="handerClick"
                        ></Menu>

                        <template v-else-if="item.type == 'Information'">
                            <Information v-for="(data, index2) in checkJson(item.data)" :key="id + 'content-info-' + index + '-sub-' + index2" :data="data" @click="handerClick" />
                            <div v-if="!item.data || checkJson(item.data).length == 0" class="nocontent" :key="id + 'content-info-no-' + index">暂无内容</div>
                        </template>

                        <PictureGroup v-if="item.type === 'PictureGroup'" :data="checkJson(item.data)" :wp="wp" :hp="hp" @on-click="handerClick"></PictureGroup>

                        <Banner
                            :circular="true"
                            :autoplay="item.autoplay"
                            :interval="item.interval"
                            :shadow="item.shadow"
                            :radius="item.radius"
                            :indicatorDots="item.indicatorDots"
                            :previousMargin="item.previousMargin"
                            :nextMargin="item.nextMargin"
                            :height="item.height"
                            :banners="checkJson(item.data)"
                            @click="handerClick"
                            :class="item.full ? 'cust-banner-swiper-full' : 'cust-banner-swiper'"
                            :key="id + 'content-banner-' + index"
                            v-else-if="item.type == 'Banner'"
                        />

                        <LFTabs :key="id + 'content-lftabs-' + index" :data="checkJson(item.data)" v-bind="item" :menuCode="menuCode" v-else-if="item.type == 'LFTabs'" />

                        <Search :key="id + 'content-search-' + index" v-bind="item" :menuCode="menuCode" v-else-if="item.type == 'Search'" />
                    </div>
                </div>
            </div>
        </CompContent>

        <CompModal title="移动端" ref="h5_modal" @on-close="h5_src = null">
            <iframe v-if="h5_src" :src="h5_src" frameborder="0" width="100%" height="100%" style="display: block;"></iframe>
        </CompModal>
    </div>
</template>

<script>
import proxy from "../../api/proxy"

import CompContent from "../customform/components/CompContent"
import CompModal from "../residentdatabase/components/CompModal.vue"

import Menu from "./components/Menu.vue"
import Information from "./components/Information.vue"
import PictureGroup from "./components/PictureGroup.vue"
import Banner from "./components/Banner.vue"
import LFTabs from "./components/LFTabs.vue"
import Search from "./components/Search.vue"

import Utils from "../jointly/utils/utils"
import Route from "../communitymobilization/entity/Route"

export default {
    components: {
        CompContent,
        CompModal,
        Menu,
        Information,
        PictureGroup,
        Banner,
        LFTabs,
        Search,
    },

    data() {
        return {
            title: "加载中...",
            modules: null,
            menuCode: this.$core.getUrlParam("systemModuleId"),

            pageError: false, //数据加载不成功
            pullBgColor: "", //下拉顶部颜色
            backgroundColor: "",
            bgImage: "./images/index_header_bg.png", //背景图片
            currPage: {
                //当前需要分页读取的模块
                index: -1,
                currentPage: 1,
                pageSize: 10,
                maxCount: 0,
                maxPage: -1,
            },
            config: {},
            h5_src: null,
            h5_domain: proxy["/custom"].target,

            // 宽比例
            wp: 1,
            // 高比例
            hp: 1,

            // 错误信息
            error: "",
        }
    },

    watch: {
        h5_src(v) {
            if (v) {
                this.$refs.h5_modal.display()
            }
        },
    },

    mounted() {
        this.$Message.loading({
            content: "加载中...",
        })

        // 计算当前屏幕
        this.processWindowSize()

        window.addEventListener("resize", this.processWindowSize.bind(this))

        this.$get("/auth/api/auth/pc/systemModule/selectById?systemModuleId=" + this.menuCode)
            .then(res => {
                if (res.code !== "200") {
                    return this.$Message.error((res.desc || res.msg || "请求失败").slice(0, 50))
                }

                if (!res.data) {
                    return (this.error = "当前页面尚未配置")
                }

                this.config = this.getConfigData(res.data.configJson)
                console.log("this.config", this.config)
                //设置背景
                // if (this.config.barBackgroundColor) {
                //     this.barBackgroundColor = this.config.barBackgroundColor
                //     this.barTextStyle = this.$core.ColorIsLight(this.barBackgroundColor) ? "#333" : "#fff"
                // }
                // if (this.config.backgroundColor) {
                //     this.backgroundColor = this.config.backgroundColor
                // }
                // if (this.config && this.config.header && !this.config.header.compFixed && this.config.header.components && this.config.header.components.length > 0) {
                //     this.pullBgColor = this.barBackgroundColor
                // } else {
                //     this.pullBgColor = this.backgroundColor
                // }
                // if (this.config && this.config.header && this.config.header.display == true) {
                //     if (this.config.header.bgImage) this.bgImage = this.config.header.bgImage
                // }
                // this.setHomeStyle()
                this.pushConfigToParent()
                this.loadApi()

                this.title = this.config.title
                this.modules = this.config.content.data
            })
            .finally(() => {
                this.$Message.destroy()
            })
    },

    // 页面卸载
    beforeDestroy() {
        window.removeEventListener("resize", this.processWindowSize)
    },

    methods: {
        processWindowSize() {
            const el = this.$refs.content.getContentEl()

            if (el.clientWidth < 800) {
                return
            }

            this.wp = el.clientWidth / 1920
            this.hp = el.clientHeight / 1080 / (1920 / 1080 / (el.clientWidth / el.clientHeight))
        },

        handerClick(params, type) {
            if (params) {
                var param = ""
                for (var key in params) {
                    if (key != "url" && key != "jumpJson") {
                        if (param != "") param += "&"
                        param += key + "=" + params[key]
                    }
                }
                if (params.url) {
                    var url = params.url
                    if (!url) return
                    if (url.indexOf("?") >= 0) {
                        url = url + "&" + param
                    } else {
                        url = url + "?" + param
                    }
                    if (/^local:\/{2,}(.*)$/.test(url)) {
                        Route.jump("/" + RegExp.$1)
                    } else if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
                        url = url + "&token=" + sessionStorage.getItem("accessToken")
                        this.h5_src = url
                    } else if (url.indexOf("original://") == 0) {
                        // //本地原生的地址：前加 original://
                        // var u = url.substring(url.indexOf("original://") + 11)
                        // if (u.indexOf("?") > -1) {
                        //     u = u.substring(0, u.indexOf("?"))
                        // }
                        // var p = this.getUrlAdrrParam(url)
                        // this.H5PlusPlugin.sync({ pluginName: "route", path: u, pageData: JSON.stringify(p) == "{}" ? "" : p })
                        this.$Message.warning("不支持APP原生跳转")
                    } else {
                        this.h5_src = this.h5_domain + "/#" + encodeURI(url) + (/.*[\?\&]$/.test(url) ? "" : url.indexOf("?") == -1 ? "?" : "&") + "token=" + sessionStorage.getItem("accessToken")
                    }
                } else if (params.jumpJson) {
                    switch (params.jumpJson.type) {
                        case "local":
                            var url = params.jumpJson.url
                            if (!url) return
                            if (url.indexOf("?") >= 0) {
                                url = url + "&" + param
                            } else {
                                url = url + "?" + param
                            }
                            Route.jump(url)
                            break
                        case "h5":
                        case "web":
                            var url = params.jumpJson.url
                            if (!url) return
                            if (url.indexOf("?") >= 0) {
                                url = url + "&" + param
                            } else {
                                url = url + "?" + param
                            }
                            var backgroundColor = this.getUrlAdrrParam(url, "backgroundColor")
                            url = url + "&token=" + sessionStorage.getItem("accessToken")
                            this.h5_src = url
                            break
                        case "app":
                            // if (!params.jumpJson.package && !params.jumpJson.iosScheme) return
                            // var url = params.jumpJson.url

                            // if (url.indexOf("?") >= 0) {
                            //     url = url + "&" + param
                            // } else {
                            //     url = url + "?" + param
                            // }
                            // url = url + "&token=" + sessionStorage.getItem("accessToken")

                            // var iosUrl = params.jumpJson.iosUrl

                            // if (iosUrl.indexOf("?") >= 0) {
                            //     iosUrl = iosUrl + "&" + param
                            // } else {
                            //     iosUrl = iosUrl + "?" + param
                            // }
                            // iosUrl = iosUrl + "&token=" + sessionStorage.getItem("accessToken")

                            // if (this.isH5Plus()) {
                            //     this.H5PlusPlugin.goActivityFunction({
                            //         androidPackage: params.jumpJson.package,
                            //         androidUrl: url,
                            //         iosScheme: params.jumpJson.iosScheme,
                            //         iosUrl: iosUrl,
                            //     })
                            // } else {
                            //     this.$Message.warning({ title: "不能访问该功能，请使用APP", icon: "none" })
                            // }
                            this.$Message.warning("不支持APP跳转")
                            break
                        case "weapp":
                            // if (!params.jumpJson.appid) return
                            // var url = params.jumpJson.url
                            // if (url.indexOf("?") >= 0) {
                            //     url = url + "&" + param
                            // } else {
                            //     url = url + "?" + param
                            // }
                            // url = url + "&token=" + sessionStorage.getItem("accessToken")
                            // this.goMiniProgram(params.jumpJson.appid, url)
                            this.$Message.warning("不支持小程序跳转")
                            break
                    }
                }
            }
        },
        //推送配置信息给父级,父级可以定义该方法
        pushConfigToParent() {
            this.$emit("pullConfigForChildren", this.config)
        },
        //父级onShow触发
        onShow() {
            // this.setHomeStyle()
            if (this.config && this.config.refresh) {
                this.onRefresh()
            }
        },
        //父级onHide触发
        onHide() {},
        onRefresh() {
            this.initHomeInfo() //初始化首页信息
        },

        //设置首页配色
        setHomeStyle() {
            //设置配色
            this.$core.setPageStatusBarStyle(this.barBackgroundColor)
            if (this.config.title) {
                Taro.setNavigationBarTitle({ title: this.config.title })
            }
        },

        //优化配置数据
        getConfigData(configData) {
            var config = JSON.parse(configData)
            // 头部
            if (config.header && config.header.components && config.header.components.length > 0) {
                config.header.components.map((item, index) => {
                    if (item && !item.data) item.data = []
                })
            }
            // 内容部
            if (config.content && config.content.data && config.content.data.length > 0) {
                config.content.data.map((item, index) => {
                    if (item && !item.data) item.data = []
                })
            }
            // tabbar
            if (config.tabbar && config.tabbar.display == true && !config.tabbar.data) {
                config.tabbar.data = []
            }
            return config
        },

        //读取所有API接口
        loadApi() {
            // 头部
            if (this.config.header && this.config.header.display == true && this.config.header.components && this.config.header.components.length > 0) {
                this.config.header.components.map((item, index) => {
                    this.loadApiData(item, index, 1)
                })
            }
            // 内容部
            if (this.config.content && this.config.content.display == true && this.config.content.data && this.config.content.data.length > 0) {
                this.config.content.data.map((item, index) => {
                    if (item.isPage && this.$core.ifNULLtoDF(item.api, null) != null) {
                        this.currPage.index = index
                        this.currPage.pageSize = item.pageSize
                        this.currPage.currentPage = 1
                        this.currPage.maxCount = 0
                        this.currPage.maxPage = 0
                    }
                    if (item.isSearch && this.$core.ifNULLtoDF(item.searchName, null) != null && this.$core.ifNULLtoDF(item.searchKeyword, null) != null) {
                        // 开启搜索栏搜索,为事件总线绑定待触发函数
                        Bus.$on(item.searchName, val => {
                            item[item.searchKeyword + "Search"] = val
                            this.loadApiData(item, index, 1)
                        })
                    }
                    // console.log("s=", item.searchName, item.searchKeyword);
                    if (item.isLFTabs && this.$core.ifNULLtoDF(item.LFTabsName, null) != null && this.$core.ifNULLtoDF(item.lftabsKeyword, null) != null) {
                        // 开启标签页搜索,为事件总线绑定待触发函数
                        Bus.$on(item.LFTabsName, val => {
                            item[item.lftabsKeyword + "LFTabs"] = val
                            this.loadApiData(item, index, 1)
                        })
                    }
                    this.loadApiData(item, index, 1)
                })
            }
            // tabbar
            if (this.config.tabbar && this.config.tabbar.display == true && this.config.tabbar.api) {
                this.loadApiData(this.config.tabbar)
            }
        },

        loadApiData(conf, index, page) {
            if (conf && this.$core.ifNULLtoDF(conf.api, null) != null) {
                var confApi = conf.api
                let data = {
                    // ...this.$core.getGeneralApiParams(), //公用API参数
                    pageSize: conf.pageSize || 10,
                    size: conf.pageSize || 10,
                    currentPage: page,
                    currPage: page,
                    page: page,
                    menuCode: this.config.sysModuleId,
                    moduleId: this.config.code,
                }
                //转换伪参数
                if (confApi.indexOf("?") > -1) {
                    var p = Utils.paramsToJson(confApi)
                    // let regExp = new RegExp("(?<=\\$\\{).+(?=\\})", 'g');
                    for (var key in p) {
                        if (!p[key]) continue
                        if (p[key].indexOf("${") != 0) continue
                        // var v= p[key].match(regExp)
                        var v = this.regExpMatch(p[key], "${", "}")
                        if (v && v.length > 0) {
                            p[key] = data[v[0]] || localStorage.getItem(v[0]) || null
                        }
                    }
                    data = { ...data, ...p }
                    confApi = confApi.split("?")[0]
                }
                // console.log("data=",data);
                if (conf.searchKeyword && conf[conf.searchKeyword + "Search"]) data[conf.searchKeyword] = conf[conf.searchKeyword + "Search"]
                if (conf.lftabsKeyword && conf[conf.lftabsKeyword + "LFTabs"]) data[conf.lftabsKeyword] = conf[conf.lftabsKeyword + "LFTabs"]
                if (page <= 1)
                    this.$Message.loading({
                        content: "加载中...",
                        duration: 0,
                    })

                this.$get(/^http.*/.test(confApi) ? confApi : "https://liefeng-gateway.test.gongxiangplat.com/apps/" + confApi, data)
                    .then(res => {
                        // console.log(res);
                        if (res && res.code == "200") {
                            if (res.dataList) {
                                //数组
                                var datalist = res.dataList
                                //参数互换，若参数不存在，则视为常量参数
                                if (conf.convertParams) {
                                    try {
                                        var cParams = JSON.parse(conf.convertParams)
                                        datalist.map(item => {
                                            for (var key in cParams) {
                                                //分解对像层级
                                                var objs = cParams[key].split(".")
                                                var value = item[objs[0]]
                                                if (objs.length > 1) {
                                                    for (var i = 1; i < objs.length; i++) {
                                                        if (value) value = value[objs[i]]
                                                    }
                                                }

                                                if (value === undefined) {
                                                    //视为常量参数
                                                    item[key] = cParams[key]
                                                } else {
                                                    item[key] = value
                                                }
                                                // console.log(item[key],"---");
                                            }
                                        })
                                    } catch (e) {}
                                }
                                if (page > 1) {
                                    if (!conf.data) conf.data = []
                                    conf.data = conf.data.concat(datalist)
                                } else {
                                    conf.data = datalist
                                }
                                //当前分页数据
                                if (index == this.currPage.index) {
                                    this.currPage.currentPage = res.data.currentPage
                                    this.currPage.maxCount = res.data.maxCount
                                    this.currPage.maxPage = res.data.maxPage
                                    // console.log(this.currPage);
                                }
                            } else if (res.data) {
                                //对象
                                var cdata = res.data.data
                                //参数互换，若参数不存在，则视为常量参数
                                if (conf.convertParams) {
                                    try {
                                        var cParams = JSON.parse(conf.convertParams)
                                        for (var key in cParams) {
                                            //分解对像层级
                                            var objs = cParams[key].split(".")
                                            var value = cdata[objs[0]]
                                            if (objs.length > 1) {
                                                for (var i = 1; i < objs.length; i++) {
                                                    if (value) value = value[objs[i]]
                                                }
                                            }

                                            if (value === undefined) {
                                                //视为常量参数
                                                cdata[key] = cParams[key]
                                            } else {
                                                cdata[key] = value
                                            }
                                            // console.log(cdata[key],"---");
                                        }
                                    } catch (e) {}
                                }
                                conf.data = cdata
                            }
                        }
                    })
                    .finally(this.$Message.destroy)
            }
        },

        //读取分页数据，仅限页面最后一个带分页的API
        loadPageInfo(params) {
            // console.log(this.currPage);
            if (this.currPage.index > -1 && this.currPage.currentPage < this.currPage.maxPage && this.currPage.maxPage > 0) {
                this.loadApiData(this.config.content.data[this.currPage.index], this.currPage.index, this.currPage.currentPage + 1)
            }
        },

        checkJson(data) {
            if (data && typeof data == "string") {
                return JSON.parse(data)
            } else {
                return data || null
            }
        },

        /**
         * 取头尾匹配的值
         * 例：取以${开头，以}结尾的所有值
         * ios上不支持正则的零宽断言的解决方法
         */
        regExpMatch(str, start, end) {
            var v = []
            if (str && start && end) {
                var li = str.indexOf(start)
                if (li > -1) {
                    var rStr = str.substring(li + start.length)
                    var ri = rStr.indexOf(end)
                    if (ri > -1) {
                        var tStr = rStr.substring(0, ri)
                        v.push(tStr)
                        var lStr = rStr.substring(ri + end.length)
                        if (lStr) {
                            var oV = this.regExpMatch(lStr, start, end)
                            v = v.concat(oV)
                        }
                    }
                }
            }
            return v
        },

        //获取指定的URL参数
        getUrlAdrrParam(url, param) {
            if (param) {
                //指定某个参数
                return decodeURIComponent((new RegExp("[?|&]" + param + "=" + "([^&;]+?)(&|#|;|$)").exec(url) || [, ""])[1].replace(/\+/g, "%20")) || null
            } else {
                //输出所有参数
                //判断是否含有问号，有则说明含有参数
                if (url.indexOf("?") !== -1) {
                    var obj = {}
                    try {
                        var str = url.split("?")[1]
                        var strs = str.split("&")
                        for (var i = 0; i < strs.length; i++) {
                            if (strs[i].split("=")[0]) obj[strs[i].split("=")[0]] = strs[i].split("=")[1]
                        }
                    } catch (e) {}
                    return obj
                } else {
                    return {}
                }
            }
        },

        X(w) {
            if (!w || (typeof w !== "number" && !/^[0-9]+(\.[0-9]+)?$/.test(w))) {
                return w || "auto"
            }

            return w * this.wp + "px"
        },

        Y(h) {
            if (!h || (typeof h !== "number" && !/^[0-9]+(\.[0-9]+)?$/.test(h))) {
                return h || "auto"
            }

            return h * this.hp + "px"
        },
    },
}
</script>

<style lang="less">
.allocatepage-index {
    .module-box {
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        min-width: 800px;

        > .item-base {
            display: grid;

            &:empty {
                display: none;
            }

            > .item-box {
                width: 100%;
                overflow-y: auto;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                    display: none; /* 对于Chrome, Safari和Opera */
                }

                &:empty {
                    display: none;
                }

                .nocontent {
                    width: 100%;
                    text-align: center;
                    margin: 30px 0;
                }
            }
        }
    }
}
</style>
