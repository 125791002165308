<template>
    <div class="liefeng-search suiyue">
        <div :class="isFixed ? 'search_fixed' : 'search_relative'">
            <Input v-model.trim="searchVal" search enter-button :placeholder="placeholder || '输入关键字搜索'" @on-search="searchFn" />
            <!-- <AtSearchBar ref="searchbar" :showActionButton="showActionButton" :placeholder="placeholder" :actionName="actionName" :inputType="inputType" v-model.trim="searchVal" :onActionClick="searchFn" :onChange="changeSearchValFn" /> -->
        </div>
        <div class="fixedHeight" v-if="isFixed"></div>
    </div>
</template>

<script>
/**
 * import Search from "@/components/Search";
 * 通用搜索栏组件,搜索事件使用：@click=""
 */
import Bus from "@/utils/Bus"
import Route from "../../communitymobilization/entity/Route"
export default {
    props: {
        isFixed: {
            type: Boolean,
            default: false,
        },
        showActionButton: {
            type: Boolean,
            default: true,
        },
        name: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        actionName: {
            type: String,
            default: "搜索",
        },
        inputType: {
            type: String,
            default: "text",
        },
        backgroundColor: {
            type: String,
            default: "#ed4014",
        },
        redirect: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: "text",
        },
        keyword: {
            type: String,
            default: "text",
        },
    },
    data() {
        return {
            searchVal: "",
            textColor: "#ffffff",
            timer: null,
            bgColor: "#ed4014",
        }
    },
    methods: {
        searchFn() {
            if (!this.searchVal) {
                this.$Message.error("请输入搜索内容")
                return
            }

            if (this.redirect) {
                //跳转页面
                if (this.url && this.keyword) {
                    let u = this.url
                    if (u.indexOf("?") >= 0) u = u + "&"
                    else u = u + "?"
                    u = u + this.keyword + "=" + this.searchVal
                    Route.jump(u)
                } else {
                    this.$Message.warning("跳转参数不完整，无法跳转。")
                }
            } else {
                this.$emit("click", this.searchVal)
                if (this.name) Bus.$emit(this.name, this.searchVal)
            }
        },
    },
    // mounted() {
    //     if (!this.backgroundColor) this.bgColor = "#ed4014"
    //     else this.bgColor = this.backgroundColor
    //     this.textColor = this.$core.ColorIsLight(this.bgColor) ? "#333" : "#fff"
    //     this.$el.style.setProperty("--textColor", this.textColor)
    //     this.$el.style.setProperty("--backgroundColor", this.bgColor)
    //     this.$el.style.setProperty("--height", (this.$refs.searchbar.$el.clientHeight || 55) + "px")
    //     // console.log(this.$refs.searchbar.$el.clientHeight);
    // },
}
</script>

<style lang="less">
.liefeng-search {
    width: 100%;
    position: relative;
    z-index: 10;
    padding: 10px;
}
</style>
